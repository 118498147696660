#painelvideos{
  padding: 10px;
  /*height: 400px;*/  
  min-height: 255px;
  max-height: 400px;  
}

#painelvideos div{
  background-color: white;
  margin-top: 20px;
  margin-left: 7%;
  padding-top: 10px;
}

#painelvideos img{
  width: 100%;
}

#painelvideos h5{
  width: 100%;
  font-size: 1.3em;
  text-align: center;
  color: black;
  padding-top: 5px;
}

#painelvideos p{
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  color: black;
}

#painelvideos a{
  text-decoration: none;
}
