.cart-item{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}

.cart-item-image{
    width: 70px;
    border-radius: 10px;
}

.cart-item-title{
    font-size: 0.90rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.cart-item-price{
    font-size: 25px;
    font-weight: 500;
    display: inline-block;
    position: absolute;
    right: 0;
}

.cart-item-content{
    padding: 0 35px 0 10px;
}

.button__remove-item{
    position: absolute;
    top: 0;
    right: 0;
    color: #d83232;
    font-size: 1.4rem;
    border: none;
    background: none;
    cursor: pointer;
}
.cart-item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
}

.cart-item-infoQtd {
    display: flex;
    align-items: center;
    -webkit-box-align: center;
}

.cart-item-button-incDecreQtd {
    color: #fff;
    font-size: 16px;
    background-color: #E76114;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    /**/
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    direction: ltr;
    text-rendering: optimizeLegibility;
    cursor: pointer;
    padding: 0;
    border: 0;
}

.cart-item-inputQtd {
    width: 100%;
    max-width: 40px;
    height: 30px;
    font-size: 14px;
    color: #000;
    border-radius: 3px;
    border: 1px solid #D6D6D6;
    margin: 0 5px;
    text-align: center;
    display: block;
    box-sizing: border-box;
    padding: 0.5rem;
    background-color: #fff;
    font-weight: 400;
    line-height: 1.5;
    appearance: none;
    overflow: visible;
}