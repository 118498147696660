#formulario {
    background-color: white;
    padding: 15px;
    color: black;
}

#grid_produtos {
    background-color: white;
    padding: 15px;
    color: black;
}

#formulario legend {
    width: 100%;
    text-align: center;
}

#grid_produtos legend {
    width: 100%;
    text-align: center;
}

#menuapp {
    display: none;
}

.width_cemporcento {
    width: 100%;
}

#grid_produtos table {
    background-color: white;
    width: 100%;
}

#grid_produtos th {
    font-weight: bold;
    border: 1px solid rgb(243, 240, 231);
    text-align: center;
    background-color: #d3d3d3;
}

#grid_produtos td {
    border: 1px solid rgb(243, 240, 231);
    padding: 5px;
}