/*footer{
    background-color:black;
    color:white;
}

#footer_gan{
    position:absolute;
    bottom:0;
    width:100%;
}*/

#navbar{
    padding-top:40px;
}

#navbar ul li a{
    color:white;
}

#menu #menu1 img {
    width:150px;
    padding:5px;
}

#menu li{
    padding-left:35px;
}

#navbar ul li a:hover {
    color: black;
    background-color:rgb(255,217,42);
}

.botao_patos{
    /*background-color:black;*/
    color:white;
    border-radius:6px;
    padding-top:6px;
    padding-bottom:6px;
    padding-left:15px;
    padding-right:15px;
}
.botao_patos:hover{
    color:yellow;
}

.botao_premio{
    background-color:black;
    color:yellow;
    font-size:24px;
    border-radius:6px;
    border-color:yellow;
    margin-top:20px;
    margin-bottom:20px;
    padding-top:6px;
    padding-bottom:6px;
    padding-left:30px;
    padding-right:30px;
    width:95%;
}
.botao_premio:hover{
    color:white;
    border-color:white;
}