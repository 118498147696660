#catalogo input[type='number']::-webkit-inner-spin-button,
#catalogo input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#catalogo input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

#catalogo input[type='number']::-webkit-inner-spin-button,
#catalogo input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#catalogo input[type='number'] {
  outline: none;
  border-right: 1px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding: 1%;
  border-right: 0px solid black;
  margin-bottom: 15px;
  width: 4ch;
}

#catalogo input,
#catalogo #btnQntd {
  font-size: inherit;
  padding: 0.2em;
  text-align: center;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

#catalogo #btnQntd {
  padding: 1%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #e76114;
  /* border-left: 0px solid black; */
  padding-left: 5%;
  padding-right: 5%;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  width: 10px;
  height: 25px;
}

#catalogo button.botaoMenos {
  border-right: 0px solid black;
  border-left: 1px solid black;
}

#catalogo button.botaoMais {
  border-right: 1px solid black;
  border-left: 0px solid black;
}

.card {
  width: 100%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border: none;
}
.cartaoVacina {
  border: solid 2px #c0c0c0;
  border-radius: 20px;
  padding: 20px;
  width: 230px;
  height: 420px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.cartaoVacina:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.cartaoVacinaImage {
  width: 100%;
}
.cartaoVacinaTitulo {
  font-size: 1.1rem;
  padding: 10px 0;
  margin: 0 auto;
  line-height: 1.2;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: normal;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  min-height: 2.8em;
  max-height: 2.8em;
  white-space: normal;
}
.cartaoVacinaDescricao {
  padding: 5px 0;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: normal;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  line-height: 15px;
  min-height: 2lh;
  max-height: 2.3lh;
  white-space: normal;
}
.cartaoVacinaPreco {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin: 8px 5px 3px;
}
.cartaoVacinaComprar {
  /* position: absolute; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #e76114;
  font-size: 1rem;
  margin-bottom: 30px;
  margin: 10px;
}
.cartaoVacinaComprar:hover {
  color: #e76114;
  border-color: #e76114;
}

.card-item-button-incDecreQtd {
  color: #fff;
  font-size: 16px;
  background-color: #e76114;
  border-radius: 3px;
  height: 35px;
  width: 40px;
  max-width: 40px;
  /**/
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  cursor: pointer;
  padding: 0.2rem;
  border: 1px solid #e76114;
  margin: 0 1px;
}
.card-item-inputQtd {
  width: 100%;
  max-width: 40px;
  height: 30px;
  font-size: 14px;
  color: #000;
  border-radius: 3px;
  border: 1px solid #d6d6d6;
  margin: 0 1px;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 0.5rem;
  background-color: #fff;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  overflow: visible;
}
.card-item-quantidade-container {
  display: flex;
  align-items: center;
  margin: 5px;
}
.card-item-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
