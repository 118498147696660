.IndexPageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
}

.indexFront {
  /* background-image: url('../../imagens/img/fotos/banners/bannerHome.jpg'); */
  background-image: url('../../imagens/img/fotos/banners/bannerNovo2.jpg');
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: row;
}

.div-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Alinha as divs à direita da logo */
  margin-left: 20px;
  /* Espaçamento entre a logo e as divs */
  text-align: right;
}

.areaLoginAdm {
  margin-top: 15%;
  background-color: white;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.btnArea {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.btnLogin {
  background-color: #0b5ed7;
  border-color: #0b5ed7;
}

.btnLogin:hover {
  background-color: #23cf5c;
  border-color: #23cf5c;
}

.btnVoltar {
  background-color: orange;
  border-color: orange;
}

.btnVoltar:hover {
  background-color: rgb(190, 126, 7);
  border-color: rgb(190, 126, 7);
}
