.EstruturaPrincipal {
  background-color: rgb(243, 240, 231);
  width: 100%;
  height: 100vh;
  padding: 5%;
  padding-top: 3%;
}

#nav {
  background-color: white;
}

#nav li {
  padding: 5%;
  padding-left: 10%;
}

#nav a {
  color: black;
  text-decoration: none;
}

#nav li a {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
}

#icone {
  background-color: white;
  padding: 10px;
  width: 100%;
  /*padding-left: 35%;*/
  border: 1px solid rgb(221, 213, 213);
}

#formulario {
  background-color: white;
  padding: 18px;
  color: black;
  padding-top: 50px;
}

#grid_produtos {
  background-color: white;
  padding: 15px;
  color: black;
}

#formulario legend {
  width: 100%;
  text-align: center;
}

#grid_produtos legend {
  width: 100%;
  text-align: center;
}

.width_cemporcento {
  width: 100%;
}

#grid_produtos table {
  background-color: white;
  width: 100%;
}

#grid_produtos th {
  font-weight: bold;
  border: 1px solid rgb(243, 240, 231);
  text-align: center;
  background-color: #d3d3d3;
}

#grid_produtos td {
  border: 1px solid rgb(243, 240, 231);
  padding: 5px;
}
