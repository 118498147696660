#titulopage {
  font-size: 40px;
  width: 100%;
  text-align: center;
}

#analises {
  width: 100%;
  align-items: center;
}

#lancamentos {
  width: 100%;
  align-items: center;
  background-color: white;
}

#lancamentos p {
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.form_lancamento {
  color: #212529;
  border-color: #dee2e6;
}

.form_lancamento:focus {
  color: #212529;
  border-color: #dee2e6;
}

#analises p {
  width: 100%;
  text-align: center;
  font-size: 15px;
}

#mediadiaria {
  background-color: rgb(241, 239, 236);
  padding: 10px;
  margin-left: 10px;
  padding-top: 35px;
}

#mediadiaria h3 {
  font-size: 25px;
  width: 100%;
  text-align: center;
}

#mediadiaria h5 {
  font-size: 10px;
  width: 100%;
  text-align: center;
}

#mediadiaria h1 {
  font-size: 35px;
  width: 100%;
  text-align: center;
}

#grafico {
  background-color: rgb(241, 239, 236);
  padding: 10px;
  margin-left: 10px;
}

#pedido {
  background-color: rgb(241, 239, 236);
  padding: 10px;
  margin-left: 10px;
  padding-top: 35px;
}

#pedido h3 {
  font-size: 25px;
  width: 100%;
  text-align: center;
}

#pedido h5 {
  font-size: 10px;
  width: 100%;
  text-align: center;
}

#pedido h1 {
  font-size: 35px;
  width: 100%;
  text-align: center;
}
