.carrinhoPedido {
    font-size: 2.0rem;
    text-align: center;
    padding-Right: 0px;
    padding-inline-start: 0px;
    border: none;
    width: 100px;
    height: 100px;
    position: relative;
    align-items: center;
    color: #111;
    background-color: #E76114;
    border-radius: 50%;
    justify-content: center;
}

.carrinhoPedido .carrinhoPedido-visible{
    display: none;
}

.carrinhoPedido .legenda {
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    text-align: center;
    display: block;
    padding-bottom: 20px;
    line-height: 18px;
}

.cart-status {
    background-color: red;
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 13px;
    font-weight: 600;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}