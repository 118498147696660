.videoWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 56.25%; /* Proporção 16:9 */
  padding-top: 30px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 10px;
}

.carousel-container {
  margin-top: -10%;
}
.carousel-container .carousel {
  max-width: 80%;
  overflow: hidden;
}

.card-icon {
  font-size: 70px;
  color: #e76114;
}

@media only screen and (max-width: 900px) {
  .carousel-container {
    margin-top: 20px;
  }
  .carousel-container .carousel {
    max-width: 100%;
  }
  .videoWrapper {
    padding-top: 0;
  }
  .videoWrapper iframe {
    width: 100%;
    height: 100%;
  }

  #ifrForm {
    width: 400px;
  }
}

@media only screen and (max-width: 850px) {
  #ifrForm {
    width: 350px;
  }
}

@media only screen and (max-width: 750px) {
  #ifrForm {
    width: 300px;
  }
}
