#feedback {
  background: #111;
  color: #d8d5d5;
  position: relative;
}

.areaFeedback {
  position: relative;
}

.section-title-feedback {
  font-size: 32px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  color: #f06718;
}

.textFeedback {
  z-index: 2;
}

.imgLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  max-width: 25%;
  padding: 20px;
}

.imgLogoBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 90%;
  filter: grayscale(100%);
  opacity: 0.1;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  margin: 0;
  padding: 0;
}

.imgHands {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 95px;
  padding: 5px;
}

@media only screen and (max-width: 900px) {
  .imgLogo {
    max-width: 70%;
  }

  .carousel-container .carousel {
    max-width: 100%;
  }

  .videoWrapper {
    padding-top: 0;
  }

  .videoWrapper iframe {
    width: 100%;
    height: 100%;
  }

  #ifrForm {
    width: 400px;
  }
}

@media only screen and (max-width: 850px) {
  #ifrForm {
    width: 350px;
  }
}

@media only screen and (max-width: 750px) {
  #ifrForm {
    width: 300px;
  }
}