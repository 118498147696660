.cart{
 width: 100%;   
 max-width: 400px;
 background-color: white;
 height: 100vh;
 position: fixed;
 top: 0;
 right: 0;
 padding: 10px 20px 20px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 transform: translate(110%, 0);
 transition: all 400ms ease;
}

.cart-items{
    flex-grow: 1;
    overflow: auto;    
}

.cart-title{
    padding-top: 5px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    color: rgb(312,10,10);
}

.cart-resume{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 20px 0 15px;
}

.cart-items .cart-item:last-child{
    border-bottom: none;
}

.cart-active {
    transform: translate(0,0);
    z-index: 10;
}

.bottomCart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
    border-bottom: none;
}

.btnFecharPedido{
    max-height: 35px;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    background: #E76114;
    color: #fff;
    font-weight: 100;
}

.btnFecharPedido:hover{
    color: black;
}

.cart-body {
    padding: 15px 10px 10px;
}

#semProdutos {
    height: 100%;
    align-items: center;
    display: flex;
}

.carrinhoVazio{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    text-align: center;
}

.carrinhoVazio-icon svg{
    font-size: 38px;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    border-style: none;
    color: #9A9A9A;
}

.carrinhoVazio-title{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    color: #9A9A9A;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 15px auto 20px;
}

.carrinhoVazio-subtitle{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #9A9A9A;
}