.IndexPageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.indexFront {
  /* background-image: url('../../imagens/img/fotos/banners/bannerHome.jpg'); */
  background-image: url('../../imagens/img/fotos/banners/bannerNovo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: row;
  text-align: center;
}

.indexLogo {
  width: 50%;
  height: 50%;
  text-align: center;
}

.logo {
  max-width: 100%; /* Garante que a logo não ultrapasse a largura da div */
  max-height: 50vh; /* Altura máxima da logo */
}

.div-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha as divs à direita da logo */
  margin-left: 20px; /* Espaçamento entre a logo e as divs */
  margin-right: 20px; /*teste*/
  text-align: right;
}

.selectAreaFranquia {
  padding: 20px 30px;
  width: 100%;
  padding-top: 20px;
}

.selectAreaFranquia select {
  max-width: 500px;
  width: 50%;
}

.selectAreaFranquia input {
  margin-top: 20px;
  width: 130px;
}

.selectAreaFranquia p {
  color: #fff;
}

.wrap-loginArea {
  background: rgba(231, 97, 20, 0.55);
  z-index: 5555;
  backdrop-filter: blur(3.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  -webkit-backdrop-filter: blur(3.5px);
}

.wrap-loginArea p {
  font-size: 20px;
  border-color: white;
  text-align: center;
}
