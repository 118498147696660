.checkbox-list label {
    font-size: 12px;
    cursor: pointer;
    padding-left: 10px;
}

.checkbox-list-container p {
    font-size: larger;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    background-color: white;
    padding: 5px;
    text-align: center;
}

.checkbox-list-container {
    margin-top: 5px;
    background-color: rgb(241, 239, 236);
    padding: 5px;
}

/* Estilização dos checkboxes */
input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]:checked {
    background-color: #333;
    border-color: #333;
}

.checkbox-list-container {
    max-height: 134px;
    /* Altura máxima do contêiner */
    overflow-y: auto;
    /* Adiciona scroll vertical quando o conteúdo excede a altura */
}

#checkboxlist-princ {
    background-color: rgb(241, 239, 236);
    padding: 10px;
    width: 2000px;
}

#checkboxlist-princ p {
    background-color: white;
    text-align: center;
}