/*
* Mate - Bootstrap 4 Template
* Build Date: December 2017
* Last updated: December 2017
* Version: 1.0
* Author: UIdeck
* Copyright (C) 2018 UIdeck
*/
/* ------------------------------------- */
/* TABLE OF CONTENTS
/* -------------------------------------
  1. IMPORT GOOGLE FONTS
  2. GENERAL
    -2.1 SECTION TITLE
    -2.2 BUTTONS
    -2.3 SOCIAL ICON
    -2.4 PRE LOADER
    -2.5 SCROLL TO UP
  3. HERO AREA 
  4. NAVBAR
  5. SERVICES SECTION
  6. FEATURES SECTION
  7. FRANQUEADO_HOME SECTION
  8. PORTFOLIO SECTION
  9. PRICING TABLE 
  10. COUNTER SECTION
  11. TESTIMONIAL AREA
  12. TEAM SECTION
  13. BLOG SECTION
  14. CONTACT SECTION
  15. FOOTER SECTION
    -15.1 COMING SOON PAGE
  16. SAIBAMAIS SECTION
  17. CALENDARIO SECTION
  18. CABE�ALHO CARRINHO
  ------------------------------------- */
/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700');

/* ==========================================================================
   2. GENERAL
   ========================================================================== */
body {
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

p {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
}

a:hover,
a:focus {
  color: #e76114;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

a:not([href]):not([tabIndex]) {
  color: #fff;
}

a:not([href]):not([tabIndex]):focus,
a:not([href]):not([tabIndex]):hover {
  color: #fff;
}

/* ==========================================================================
   2.1 Section Title
   ========================================================================== */
.section {
  padding: 80px 0 20px 0;
}

.section-header {
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.section-header .section-title {
  font-size: 32px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  position: relative;
}

.section-header .section-title span {
  color: #61d2b4;
}

.section-header .section-subtitle {
  margin-top: 15px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.section-header .lines {
  margin: auto;
  width: 70px;
  position: relative;
  border-top: 2px solid #e76114;
  margin-top: 15px;
}

.section .item-boxes h4 {
  font-size: 18px;
}

/* ==========================================================================
   2.2 Buttons
   ========================================================================== */
.btn {
  font-size: 14px;
  padding: 11px 40px;
  border-radius: 0px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
  /* color: #fff; */
}

.btn-common {
  border: 1px solid #e76114;
  background: #e76114;
  padding: 11px 40px;
  position: relative;
  color: #fff;
  z-index: 1;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-weight: 400;
}

.btn-exit {
  border: 1px solid #e76114;
  background: #fff;
  position: relative;
  color: #e76114;
  z-index: 1;
  border-radius: 30px;
  cursor: pointer;
}

.btn-common:hover {
  color: #e76114;
  background: #fff;
  border-color: #e76114;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.btn-border {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
}

.btn-border:hover {
  border: 2px solid #fff;
  color: #fff;
  background-color: #61d2b4;
}

.btn-lg {
  padding: 14px 33px;
  text-transform: uppercase;
  font-size: 16px;
}

.btn-rm {
  padding: 7px 0px;
  color: #999;
  text-transform: capitalize;
}

.btn-rm i {
  vertical-align: middle;
}

button:focus {
  outline: none !important;
}

.clear {
  clear: both;
}

/* ==========================================================================
   2.3 Social Iocns
   ========================================================================== */
.social-icons {
  margin-top: 40px;
}

.social-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-icons ul li {
  display: inline;
}

.social-icons ul li a {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 15px;
  border-radius: 50%;
  line-height: 35px;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #ddd;
  font-size: 16px;
}

.social-icons ul li a:hover {
  color: #fff;
}

.facebook a {
  color: #4867aa;
}

.facebook a:hover {
  background: #4867aa;
}

.twitter a {
  color: #1da1f2;
}

.twitter a:hover {
  background: #1da1f2;
}

.google-plus a {
  color: #dd4d42;
}

.google-plus a:hover {
  background: #dd4d42;
}

.youtube a {
  color: #df2926;
}

.youtube a:hover {
  background: #df2926;
}

.linkedin a {
  color: #007bb6;
}

.linkedin a:hover {
  background: #007bb6;
}

.pinterest a {
  color: #bd081c;
}

.pinterest a:hover {
  background: #bd081c;
}

.dribbble a {
  color: #ea4c89;
}

.dribbble a:hover {
  background: #ea4c89;
}

.behance a {
  color: #0b7cff;
}

.behance a:hover {
  background: #0b7cff;
}

/* ==========================================================================
	 2.4  Pre Loader
	 ========================================================================== */
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  z-index: 9999999999;
}

.spinner {
  width: 40px;
  height: 40px;
  top: 45%;
  position: relative;
  margin: 0px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #61d2b4;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* ========================================================================== 
  -2.5 Scroll To Up 
   ========================================================================== */
.back-to-top {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15px;
}

.back-to-top i {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: #e76114;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

/* ==========================================================================
   3. Hero Area
   ========================================================================== */
#hero-area {
  /*background: url(../../imagens/img/fotos/fotos/clinica/clinica3.jpg ); /*fixed no-repeat;
  background-repeat: space;
  background-size:cover;
  background-attachment: fixed;  
  background-position: center left;
  /*background-position: left -250px;*/
  color: #fff;
  overflow: hidden;
  position: relative;
}

#hero-area .banner {
  position: absolute;
  align-items: center;
  z-index: 0;
  max-height: 493px;
}

.bannerIndex {
  position: fixed;
}

imgLogoSite {
  width: 100%;
}

@media only screen and (min-width: 1400px) {
  #hero-area {
    height: 493px;
  }
}

@media only screen and (min-width: 1140px) and (max-width: 1335px) {
  #imgLogoSite {
    width: 80%;
  }
}

#hero-areaIndex {
  /*background: url(../../imagens/img/fotos/banners/bannerHome.jpg) fixed no-repeat;
  background-size:cover;
  background-position: right;*/
  margin: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
  color: #fff;
}

#imgAreaIndex {
  position: fixed;
  height: 100vh;
  width: 150%;
  max-width: 3150px;
  max-height: 1000px;
  right: 0;
}

#hero-areaIndex .title {
  padding-top: 40vh;
}

#hero-area .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #61d2b4;
  opacity: 0.9;
}

#hero-area .navbar .container {
  padding: 0;
}

#hero-area .contents {
  padding: 200px 0 130px;
  position: relative;
}

#hero-area .contents h1 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 25px;
}

#hero-area .contents p {
  font-size: 16px;
  /*color: #E76114;*/
  color: #222;
  font-weight: 550;
  line-height: 30px;
  letter-spacing: 0.5px;
  /*text-shadow: 1px 1px 2px black;*/
}

#hero-area .contents .btn {
  margin: 25px 25px 0px 0px;
  text-transform: uppercase;
}

#hero-area .banner_bottom_btn {
  margin-top: 40px;
}

#hero-area .banner_bottom_btn i {
  color: #fff;
  font-size: 48px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#hero-area .banner_bottom_btn i:hover {
  color: #61d2b4;
}

.wrap-loginArea {
  background: rgba(231, 97, 20, 0.55);
  z-index: 5555;
  backdrop-filter: blur(3.5px);
  /*width: 350px;  
  left: 90%;
  margin: 100px 0 0 0;*/
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  -webkit-backdrop-filter: blur(3.5px);
}

.wrap-loginArea p {
  font-size: 20px;
  border-color: white;
  text-align: center;
}

.loginArea-form {
  width: 100%;
  padding: 45px 45px 37px 45px;
}

.loginArea-logo {
  font-size: 80px;
  display: flex;
  color: #333333;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.loginArea-subtitle {
  font-size: 16px;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  display: block;
  padding-top: 20px;
}

.loginArea-button {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
}

.loginArea-button button {
  font-size: 16px;
  color: #fff;
  border-color: black;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 25px;
  position: relative;
  z-index: 1;
  transition: all 0.4s;
  cursor: pointer;
}

.loginArea-button button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

.loginArea-Select {
  padding: 40px 0px 20px 0px;
  align-items: center;
  justify-content: center;
  display: grid;
  place-items: center;
}

.loginArea-Select select {
  width: 250px;
  min-height: 35px;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #fff;
  outline: none;
  padding: 0px 10px;
  cursor: pointer;
}

/* ==========================================================================
   4. Navbar Style
   ========================================================================== */
.is-sticky {
  width: 100%;
  top: 0;
  border-radius: 0 0 20px 2 0px;
  background: blue;
}

.navbar-brand {
  position: relative;
  padding: 0px;
}

.top-nav-collapse {
  z-index: 9;
  top: 0px !important;
  padding: 0;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  background: #fff !important;
}

.top-nav-collapse .navbar-brand {
  top: 0px;
}

.top-nav-collapse .navbar-nav .nav-link {
  color: #333 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.top-nav-collapse .navbar-nav .nav-link a {
  text-decoration: none;
  color: #333 !important;
}

.top-nav-collapse .navbar-nav .nav-link:hover a {
  color: #e76114 !important;
}

.top-nav-collapse .navbar-nav .nav-link.active {
  color: #fff !important;
  background: #e76114;
}

.top-nav-collapse .navbar-nav .nav-link.active a {
  color: #fff !important;
}

.indigo {
  background: transparent;
}

.navbar-expand-lg {
  padding: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  padding: 0 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 40px;
  border-radius: 30px;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-right: 0px;
}

.navbar-expand-lg .navbar-nav .nav-link a {
  color: #fff;
  text-decoration: none;
}

.navbar-expand-lg .navbar-nav .nav-link li {
  color: #fff;
  padding: 0 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 40px;
  border-radius: 30px;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-expand-lg .navbar-nav li:hover > a,
.navbar-expand-lg .navbar-nav li .active > a,
.navbar-expand-lg .navbar-nav li:focus > a {
  /*color: #61D2B4;*/
  color: #e76114;
  /*laranja*/
}

.navbar-nav .nav-item .nav-link.active {
  color: #fff !important;
  /*background: #61D2B4;*/
  /*background: #643E00;*/
  background: #e76114;
}

.dropdown-toggle::after {
  display: none;
}

.slicknav_btn {
  border-color: #e76114;
}

.slicknav_menu .slicknav_icon-bar {
  background: #e76114;
}

.navbar-bgTransparente {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
}

/* only small tablets */
@media (min-width: 768px) and (max-width: 1140px) {
  #nav-main li a.nav-link {
    padding-top: 18px;
  }
}

.navbar-toggler {
  display: none;
}

.mobile-menu {
  display: none;
}

.slicknav_menu {
  display: none;
}

@media screen and (max-width: 1140px) {
  .navbar-header {
    width: 100%;
    padding: 2rem 1rem;
  }

  .navbar-brand {
    position: absolute;
    padding: 15px;
    top: 0;
  }

  .navbar-brand img {
    width: 70%;
  }

  #mobile-menu {
    display: none;
  }

  .slicknav_menu {
    display: block;
  }

  .slicknav_nav .active li {
    background: #e76114;
    color: #fff;
  }

  .slicknav_nav a:hover,
  .slicknav_nav .active {
    color: #e76114;
    background: #f8f9fa;
  }

  .slicknav_nav .dropdown li.active {
    background: #f8f9fa;
    color: #e76114;
  }
}

/* ==========================================================================
  5. Services section
   ========================================================================== */
#services {
  padding-top: 50px;
}

.item-boxes {
  text-align: center;
  padding: 0px 15px;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.item-boxes .icon {
  width: 60px;
  height: 60px;
  text-align: center;
  border: 1px solid #f1f1f1;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.item-boxes .icon i {
  font-size: 26px;
  line-height: 60px;
  color: #e76114;
}

.item-boxes h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.item-boxes:hover .icon {
  background: #e76114;
}

.item-boxes:hover .icon i {
  color: #fff;
}

/* ==========================================================================
  6. Features Section Style
   ========================================================================== */
#features {
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url(./imagens/img/fotos/fotos/clinica/clinicaFundo1.png) fixed no-repeat;
  background-size: cover;
  color: rgb(59, 23, 23);
  overflow: hidden;
  position: relative;
  padding-top: 60px;
}

#features .section-title {
  color: #7e618a;
}

#features .section-subtitle {
  color: #fff;
}

#features .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .box-item {
  padding: 15px 0;
}

#features .box-item .icon {
  border: 1px solid #7e618a;
  text-align: center;
  float: left;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon i {
  color: #7e618a;
  font-size: 24px;
  line-height: 60px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text {
  padding-left: 80px;
}

#features .box-item .text h4 {
  color: #7e618a;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 22px;
}

#features .box-item .text p {
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
}

#features .box-item:hover .icon {
  background: #7e618a;
}

#features .box-item:hover .icon i {
  color: #ffffff;
}

#features .show-box {
  margin: 0 auto;
}

#features .show-box img {
  bottom: -50px;
  right: -140%;
  width: 100%;
  height: 100%;
}

#features a {
  color: rgb(59, 23, 23);
}

/* ==========================================================================
  7. Video promo Style
   ========================================================================== */
.video-promo {
  /*background: #dadd12;*/
  /*background: url(../../imagens/img/fotos/banners/banner1a.jpg) fixed no-repeat;  */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./imagens/img/fotos/banners/banner1a.jpg) fixed no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  padding-bottom: 30px;
}

.video-promo .video-promo-content {
  color: #fff;
}

.video-promo .video-promo-content h2 {
  color: #e76114;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 700;
}

.video-promo .video-promo-content p {
  padding-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.video-promo .video-promo-content .video-popup {
  width: 110px;
  height: 110px;
  text-align: center;
  border: 3px solid #fff;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 15px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.video-promo .video-promo-content .video-popup i {
  padding: 12px 18px;
  font-size: 30px;
}

.video-promo .video-promo-content .video-popup span {
  font-size: 15px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #61d2b4;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

#franqueado_home .franqueado-button {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
}

#franqueado_home .franqueado-button button {
  font-size: 14px;
  color: #fff;
  background: #e76114;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 50px;
  width: 170px;
  border-radius: 25px;
  z-index: 1;
  transition: all 0.4s;
  cursor: pointer;
  white-space: nowrap;
}

#franqueado_home .franqueado-button button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

#franqueado_home .franqueado-button i {
  font-size: 20px;
  color: #000;
  padding-right: 10px;
}

#franqueado_home .video-promo-content .text-center h2 {
  font-weight: 700;
  letter-spacing: 1px;
}

/* ==========================================================================
  8. Portfolio Section
   ========================================================================== */
/*   
#portfolios .mix {
  padding: 10px;
}

#portfolios .portfolio-item .shot-item {
  margin: 0px;
}

#portfolio .mix {
  display: none;
}

.controls {
  text-align: center;
  padding: 0px 0px 20px;
}

.controls .active {
  color: #61D2B4 !important;
  border-color: #61D2B4;
  background: transparent;
}

.controls .btn {
  text-transform: uppercase;
  margin: 2px;
}

.controls:hover {
  cursor: pointer;
}

.portfolio-img {
  overflow: hidden;
  display: block;
  position: relative;
}

.portfolio-img img {
  width: 100%;
}

.shot-item {
  margin-right: 15px;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.shot-item img {
  width: 100%;
}

.shot-item .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.shot-item:hover .overlay {
  opacity: 1;
}

.overlay .item-icon {
  height: 48px;
  width: 48px;
  line-height: 48px;
  color: #61D2B4;
  left: 50%;
  margin-left: -24px;
  margin-top: -24px;
  top: 50%;
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: 1px solid #61D2B4;
  border-radius: 50%;
}

.overlay .item-icon:hover {
  background: #61D2B4;
  color: #fff;
}

.shot-item:hover .item-icon {
  visibility: visible;
  opacity: 1;
}
*/

/* ==========================================================================
	9. Pricing Table
	========================================================================== */
.pricing-table {
  padding: 0px 0px 40px 0px;
  margin: 0 auto;
  margin-bottom: 5px;
  border: transparent;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.pricing-table:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing-table .pricing-details h2 {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  background: #f1f1f1;
  padding: 30px 0;
}

.pricing-table .pricing-details span {
  display: inline-block;
  font-size: 48px;
  font-weight: 400;
  color: #61d2b4;
  margin-bottom: 20px;
}

.pricing-table .pricing-details ul {
  margin-bottom: 35px;
}

.pricing-table .pricing-details ul li {
  padding: 10px;
  font-size: 15px;
  border-bottom: 1px dotted #f1f1f1;
}

.table-left {
  margin-top: 20px;
}

.pricing-button {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
}

.pricing-button button {
  font-size: 14px;
  color: #fff;
  background: #e76114;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 50px;
  width: 170px;
  border-radius: 25px;
  position: relative;
  z-index: 1;
  transition: all 0.4s;
  cursor: pointer;
}

.pricing-button button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

#pricing .pricing-button img {
  display: flex;
  width: 35px;
  padding-right: 10px;
}

/* ==========================================================================
 10. Counter Section Style
 ========================================================================== */
.counters {
  /*background: url(../img/counter.jpg) fixed;*/
  position: relative;
}

.counters .facts-item {
  text-align: center;
  color: #fff;
}

.counters .facts-item .icon {
  margin-bottom: 30px;
}

.counters .facts-item .icon i {
  font-size: 50px;
  color: #fff;
}

.counters .facts-item .fact-count h3 {
  font-size: 35px;
  color: #fff;
  margin-bottom: 15px;
}

.counters .facts-item .fact-count h4 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

/* ==========================================================================
11. Testimonial Section Style
 ========================================================================== */
#testimonial {
  /*background: url(../img/testimonial-bg.jpg) fixed no-repeat;*/
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.testimonial-item {
  text-align: center;
}

.testimonial-item img {
  width: 80px;
  border: 3px solid #fff;
  height: 80px;
  border-radius: 50%;
}

.testimonial-item .testimonial-text h3 {
  font-size: 15px;
  font-weight: 700;
}

.testimonial-item .testimonial-text span {
  font-size: 14px;
  color: #999;
}

.testimonial-item .testimonial-text p {
  font-size: 14px;
  font-weight: 400;
  padding: 36px 10px 30px 10px;
  margin: 0;
  line-height: 30px;
}

.owl-theme .owl-controls .owl-page span {
  background: #61d2b4;
}

.owl-theme .owl-controls {
  margin-top: 20px;
}

/* ==========================================================================
  12. Team Section
   ========================================================================== */
.single-team:hover .team-details {
  opacity: 1;
}

.single-team {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #f1f1f1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

#team .flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

#team .flex div {
  flex: 1 1 200px;
  margin: 10px;
}

.single-team .team-inner {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-team .team-details {
  text-align: center;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  background: #f1f1f1;
  padding: 5px;
}

.single-team .team-details .team-title {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.single-team .team-details p {
  color: #333;
  font-size: 13px;
}

.single-team .team-inner ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 0;
}

.single-team .social-list li {
  display: flex;
  flex-direction: row;
}
.single-team .social-list ul {
  padding-left: 0;
}

.single-team .social-list li a {
  display: flex;
  height: 34px;
  line-height: 34px;
  text-align: center;
  align-items: center;
  width: 34px;
  margin: 2px;
  color: #e76114;
  font-size: 14px;
  background: #fff;
  border-radius: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.single-team .social-list .facebook a:hover {
  background: #e76114;
  color: #fff;
}

.single-team .social-list .instagram a:hover {
  background: #e76114;
  color: #fff;
}

.single-team .social-list .maps a:hover {
  background: #e76114;
  color: #fff;
}

.single-team .social-list .phone a:hover {
  background: #e76114;
  color: #fff;
}

/* ==========================================================================
  13. Blog 
   ========================================================================== */
#blog .blog-item-wrapper {
  background: #fff;
  border-radius: 0px;
  -webkit-transition: transform 0.2s linear, -webkit-box-shadow 0.2s linear;
  -moz-transition: transform 0.2s linear, -moz-box-shadow 0.2s linear;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
}

#blog .blog-item-wrapper:hover .blog-item-img:before {
  opacity: 1;
  height: 100%;
  width: 100%;
}

#blog .blog-item-img {
  position: relative;
}

#blog .blog-item-img img {
  width: 100%;
}

#blog .blog-item-text {
  padding: 20px 0;
}

#blog .blog-item-text h3 {
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

#blog .blog-item-text h3 a {
  color: #333;
}

#blog .blog-item-text h3 a:hover {
  color: #61d2b4;
}

#blog .blog-item-text p {
  line-height: 25px;
  margin-bottom: 20px;
}

.meta-tags {
  margin-bottom: 20px;
}

.meta-tags span {
  color: #999;
  margin-right: 10px;
}

.meta-tags span i {
  margin-right: 5px;
}

.meta-tags span a {
  color: #999;
}

.meta-tags span a:hover {
  color: #61d2b4;
}

/* ==========================================================================
  14. Contact Us
  ========================================================================== */
.contact-us h3 {
  font-size: 36px;
  margin-bottom: 45px;
  font-weight: 700;
}

.contact-us p span {
  color: #fff;
  padding-left: 10px;
}

#contact {
  background: #e76114;
  color: #fff;
  position: relative;
}

.contact-form button {
  border-color: black;
}

.contact-form button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

.form-control {
  width: 100%;
  margin-bottom: 10px;
  padding: 14px;
  border-color: #fff;
  border-radius: 4px;
  color: #fff;
  background: transparent;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.form-control:focus {
  box-shadow: none;
  color: #fff;
  border-color: #fff;
  background: transparent;
  outline: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
}

.text-danger {
  font-size: 14px;
  margin-top: 10px;
}

.list-unstyled li {
  color: #cc0700;
}

input::placeholder {
  color: rgb(190, 187, 187);
}

textarea::placeholder {
  color: rgb(190, 187, 187);
}

::-webkit-input-placeholder {
  color: rgb(190, 187, 187);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(190, 187, 187);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(190, 187, 187);
}

:-ms-input-placeholder {
  color: rgb(190, 187, 187);
}

/* ==========================================================================
15. Footer Style
 ========================================================================== */
footer {
  background: #643e00;
  padding: 20px 0;
}

.footer-links {
  line-height: 48px;
}

.footer-links li {
  float: left;
}

.footer-links li a {
  color: #fff;
  margin-right: 15px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.footer-links li a:hover {
  color: #e76114;
}

.copyright {
  margin-top: 10px;
  float: right;
}

.copyright p {
  color: #fff;
}

.copyright p a {
  color: #fff;
}

.copyright p a:hover {
  color: #e76114;
}

/* ==========================================================================
16. SAIBA MAIS SECTION
 ========================================================================== */

#saibamais .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 800px;
  margin: 0 auto;
  grid-gap: 20px;
  padding-top: 50px;
}

.saibamais-section p {
  text-align: justify;
  font-size: 16px;
  color: #000;
}

.saibamais-section img {
  width: 400px;
  border-radius: 10px;
  max-height: 600px;
}

/* ==========================================================================
17. SAIBA MAIS SECTION
 ========================================================================== */

.calendario-section .section_wrapper {
  margin: 0 5vw;
  position: relative;
  max-width: 1102px;
  display: flex;
}

.calendario-section .wrap {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.calendario-section .column {
  float: left;
  margin: 0 1% 40px;
}

.calendario-section .column div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
}

.calendario-section .column img {
  width: 400px;
  max-width: 100%;
  height: auto;
}

.calendario-section .column .dados {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.calendario-section .column p {
  padding: 80px 0 20px 0;
}

.calendario-section .calendario-button button {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.calendario-section .calendario-button button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

/* ==========================================================================
17. FRANQUEADO SECTION
 ========================================================================== */
#franqueado {
  margin: 0;
  position: relative;
  color: #fff;
}

#franqueado .imgBanner img {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1400px) {
  #franqueado .imgBanner img {
    width: 100%;
    height: 500px;
  }
}

#franqueado .navbar {
  background-color: transparent;
}

#franqueado .top-nav-collapse {
  z-index: 9;
  top: 0px !important;
  padding: 0;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  background: #fff !important;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}

#franqueado .titleFolder {
  position: absolute;
  z-index: 1;
  top: 0px;
  width: 100%;
}

#franqueado .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #61d2b4;
  opacity: 0.9;
}

#franqueado .contents {
  padding: 250px 0 0 0;
}

#franqueado .contents h1 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 25px;
}

#franqueado .contents p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
}

#franqueado .contents .btn {
  margin: 25px 25px 0px 0px;
  text-transform: uppercase;
}

#franqueado .banner_bottom_btn {
  margin-top: 40px;
}

#franqueado .banner_bottom_btn i {
  color: #fff;
  font-size: 48px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#franqueado .banner_bottom_btn i:hover {
  color: #61d2b4;
}

#formulario input {
  color: #000;
  border-color: #d1d1d1;
  margin: auto;
}

#formulario .btn-franqueado button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: #e76114;
  color: #fff;
}

#formulario .btn-franqueado button:hover {
  background: #fff;
  border-color: #e76114;
  color: #e76114;
}

#formulario a:hover {
  color: #e76114;
}

#formulario h3 {
  font-size: 30px;
}

/* ==========================================================================
18. CABE�ALHO CARRINHO
 ========================================================================== */

.compras {
  font-size: 16px;
  padding-right: 35px;
  padding-top: 9px;
  text-align: right;
  width: 100px;
  height: 100px;
  position: relative;
  align-items: center;
  background-color: #e76114;
  border-radius: 50%;
}

.compras compras-header {
  float: right;
  clear: right;
}

.compras img {
  height: 40px;
  width: 40px;
  line-height: 10px;
}

.compras-vacinas {
  font-size: 16px;
  padding-right: 35px;
  padding-top: 9px;
  text-align: right;
  width: 100px;
  height: 100px;
  position: relative;
  align-items: center;
  background-color: #e76114;
  border-radius: 50%;
}

.compras-vacinas ol {
  margin-bottom: 0.6em;
  padding-inline-start: 0px;
}

.compras-vacinas li {
  color: #fff;
}

.compras-vacinas img {
  height: 40px;
  width: 40px;
  line-height: 10px;
}

.compras2 {
  font-size: 14px;
  padding-top: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100px;
  height: 100px;
  position: relative;
  clear: right;
  align-items: center;
  background-color: #e76114;
  border-radius: 50%;
}

.compras2 i {
  font-size: 40px;
  margin-top: -13px;
}

.compras2 a {
  text-align: center;
  align-items: center;
}

.compras ol {
  margin-bottom: 0.6em;
  padding-inline-start: 16px;
}

.compras ol li {
  display: inline;
  text-align: center;
  list-style-type: none;
}

.compras a {
  color: #fff;
  text-decoration: none;
}

.carrinho {
  /*position:absolute;    
    bottom: 5px;    */
  text-decoration: none;
}
.carrinho a ol li {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

/*.carrinhoCompras {*/
.menuMargem {
  left: 150px;
  font-family: 'Poppins', sans-serif;
}

.modal-login {
  background: rgba(240, 127, 62, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-color: #f19967;
  color: #fff;
}

.modal-footer,
.modal-header {
  border-color: #f19967;
}

.modal-content .close button {
  opacity: 0.8;
}

.btn-forms {
  color: #000;
  border-radius: 15px;
  background: #d9d9d9;
  padding: 11px 40px;
  border-color: transparent !important;
  border-width: 1px;
}

.btn-forms:hover {
  border-color: #e76114 !important;
  color: #e76114;
}

.logoIndex {
  padding: 0 40px;
  align-items: center;
  text-align: left;
}

.logoIndex img {
  /*position:absolute;
    padding-left: 200px;*/
  position: relative;
  width: 100%;
  max-width: 400px;
  max-height: 800px;
}

.selectAreaFranquia {
  padding: 20px 30px;
  width: 100%;
  padding-top: 20px;
}

.selectAreaFranquia select {
  max-width: 500px;
  width: 50%;
}

.selectAreaFranquia input {
  margin-top: 20px;
  width: 130px;
}

.selectAreaFranquia p {
  color: #fff;
}

.indexElements {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.indexElements .linhaIndexElements {
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 40px;
}

@media only screen and (max-width: 700px) {
  .indexElements .linhaIndexElements {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .logoIndex {
    text-align: center;
  }
}

.cursorPonteiro {
  cursor: pointer;
}

.linkCarrinhoMenu {
  color: #fff;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.carrinhoPedido {
  font-size: 2rem;
  text-align: center;
  padding-right: 0px;
  padding-inline-start: 0px;
  border: none;
  width: 100px;
  height: 100px;
  position: relative;
  align-items: center;
  color: #111;
  background-color: #e76114;
  border-radius: 50%;
  justify-content: center;
}
.carrinhoPedido .legenda {
  color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  text-align: center;
  display: block;
  padding-bottom: 20px;
  line-height: 18px;
}
.cart-status {
  background-color: red;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalogo {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.img-container {
  width: 300px;
  height: 200px;
  overflow: hidden;
}
.imgCardFilial {
  width: 100%; /*255px;*/
  height: 100%; /*145px;*/
  object-fit: cover;
  max-width: 100%;
  display: block;
}
