@import url("https://fonts.googleapis.com/css?family=Poppins:400,700");

*{
  margin: 0;
  padding: 0;  
}

body {
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

p {
  font-size: 14px;
  line-height: 26px;
}

a:hover,
a:focus {
  color: #E76114;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

a:not([href]):not([tabindex]) {
  color: #fff;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #fff;
}